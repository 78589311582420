var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"data-app":""}},[_c('b-row',{attrs:{"align-h":"end"}},[_c('b-col',{attrs:{"cols":"9"}}),_c('b-col',{attrs:{"cols":"2"}},[_c('b-form-input',{staticClass:"my-1",attrs:{"append-icon":"mdi-magnify","label":"Search","hide-details":"","outlined":"","placeholder":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('b-col',{staticClass:"my-1",staticStyle:{"text-align":"end"},attrs:{"cols":"1","align-self":"end"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-end",staticStyle:{"text-align":"end"},attrs:{"align-self":"end"}},[_c('b-dropdown',{staticStyle:{"width":"180px"},attrs:{"variant":"outline-secondary","text":"Split Link","checkbox-menu":"","allow-focus":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('span',[_c('uil-columns'),_vm._v(" Columns ")],1)]},proxy:true}])},[_c('b-dropdown-form',{staticClass:"w-100"},_vm._l((_vm.fields),function(f,index){return _c('b-form-checkbox',{key:'check' + index,attrs:{"value":f.key},model:{value:(_vm.displayColumnsCheck),callback:function ($$v) {_vm.displayColumnsCheck=$$v},expression:"displayColumnsCheck"}},[_vm._v(_vm._s(f.label)+" ")])}),1)],1)],1)])],1),_c('v-data-table',{ref:"myTable",attrs:{"headers":_vm.headers,"items":_vm.opportunities,"item-key":"name","search":_vm.search},on:{"current-items":_vm.currentItems,"click:row":_vm.clicked},scopedSlots:_vm._u([_vm._l((_vm.filters),function(col,i){return {key:("header." + i),fn:function(ref){
var header = ref.header;
return [_c('div',{key:i,staticStyle:{"display":"inline-block","padding":"16px 0"}},[_vm._v(_vm._s(header.text))]),_c('div',{key:'filter'+i,staticStyle:{"float":"right","margin-top":"8px"},attrs:{"id":"attachId"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":400,"min-width":300,"bottom":"","left":"","attach":"#attachId"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.activeFilters[header.value] && _vm.activeFilters[header.value].length < _vm.filters[header.value].length ? 'red' : 'default'}},[_vm._v(" mdi-filter-variant ")])],1)]}}],null,true)},[_c('v-list',{staticClass:"pa-0"},[_c('v-list-item-group',[_vm._l((_vm.filters[header.value]),function(item){return [_c('v-list-item',{key:("" + item),attrs:{"value":item,"ripple":false}},[[_c('v-list-item-action',[_c('b-form-checkbox',{attrs:{"value":item},model:{value:(_vm.activeFilters[header.value]),callback:function ($$v) {_vm.$set(_vm.activeFilters, header.value, $$v)},expression:"activeFilters[header.value]"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item))])],1)]],2)]})],2)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('b-button',{attrs:{"text":"","block":"","variant":"primary"},on:{"click":function($event){return _vm.toggleAll(header.value)}}},[_vm._v("Toggle all")])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('b-button',{attrs:{"text":"","block":"","variant":"danger"},on:{"click":function($event){return _vm.clearAll(header.value)}}},[_vm._v("Clear all")])],1)],1)],1)],1)]}}}),{key:"item.sf_opportunity_status",fn:function(ref){
var item = ref.item;
return [_c('b-badge',{class:item.sf_opportunity_status.toLowerCase().split(' ').join(''),attrs:{"pill":"","variant":"primary"}},[_vm._v(" "+_vm._s(item.sf_opportunity_status)+" ")])]}},{key:"item.rentable_sqft",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Number(item.rentable_sqft.toFixed(2)).toLocaleString('en-CA', {minimumFractionDigits:2}))+" ")]}}],null,true)},[_c('template',{slot:"body.append"},[_c('tr',[_c('th',[_vm._v("Totals")]),_c('th'),_c('th'),_c('th',[_vm._v(_vm._s(Number(_vm.sumField('rentable_sqft')).toLocaleString('en-CA', { minimumFractionDigits: 2, })))]),_c('th')])])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }