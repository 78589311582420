<template>
<b-card data-app>
    <b-row align-h="end">
        <b-col cols="9">

        </b-col>
        <b-col cols="2">
            <b-form-input
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                hide-details
                outlined
                placeholder="Search"
                class="my-1"
            ></b-form-input>
        </b-col>
        <b-col cols="1" class="my-1" align-self="end" style="text-align: end">
            <div class="d-flex align-items-center justify-content-end" align-self="end" style="text-align: end">
                <b-dropdown style="width: 180px" variant="outline-secondary" text="Split Link" checkbox-menu allow-focus>
                    <template #button-content>
                        <span>
                            <uil-columns /> Columns </span>
                    </template>
                    <b-dropdown-form class="w-100">
                        <b-form-checkbox v-model="displayColumnsCheck" v-for="(f, index) in fields" :key="'check' + index" :value="f.key">{{ f.label }}
                        </b-form-checkbox>
                    </b-dropdown-form>
                </b-dropdown>
            </div>
        </b-col>
    </b-row>

    <v-data-table :headers="headers" :items="opportunities" item-key="name" ref="myTable" @current-items="currentItems" @click:row="clicked"   :search="search">
        <template v-for="(col, i) in filters" v-slot:[`header.${i}`]="{ header }">
            <div style="display: inline-block; padding: 16px 0;" :key="i">{{ header.text }}</div>
            <div style="float: right; margin-top: 8px" id="attachId" :key="'filter'+i">
                <v-menu :close-on-content-click="false" :max-width="400" :min-width="300" bottom left attach="#attachId">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" icon v-bind="attrs" v-on="on">
                            <v-icon small :color="activeFilters[header.value] && activeFilters[header.value].length < filters[header.value].length ? 'red' : 'default'">
                                mdi-filter-variant
                            </v-icon>
                        </v-btn>
                    </template>
                    <v-list class="pa-0">
                        <v-list-item-group>
                            <template v-for="(item) in filters[header.value]">
                                <v-list-item :key="`${item}`" :value="item" :ripple="false">
                                    <template>
                                        <v-list-item-action>
                                            <b-form-checkbox v-model="activeFilters[header.value]" :value="item"></b-form-checkbox>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title>{{item}}</v-list-item-title>
                                        </v-list-item-content>
                                    </template>
                                </v-list-item>
                            </template>
                        </v-list-item-group>
                    </v-list>
                    <v-row no-gutters>
                        <v-col cols="6">
                            <b-button text block @click="toggleAll(header.value)" variant="primary">Toggle all</b-button>
                        </v-col>
                        <v-col cols="6">
                            <b-button text block @click="clearAll(header.value)" variant="danger">Clear all</b-button>
                        </v-col>
                    </v-row>
                </v-menu>
            </div>
        </template>
        <template v-slot:item.sf_opportunity_status="{ item }">
            <b-badge
            pill
            variant="primary"
            :class="item.sf_opportunity_status.toLowerCase().split(' ').join('')"
            >
                {{ item.sf_opportunity_status }} 
             </b-badge>
        </template>
        <template v-slot:item.rentable_sqft="{ item }">
           
                {{ Number(item.rentable_sqft.toFixed(2)).toLocaleString('en-CA', {minimumFractionDigits:2})}}
        </template>
            
        <template slot="body.append">
            <tr>
                <th>Totals</th>
                <th></th>
                <th></th>
                <th>{{                 
                  Number(sumField('rentable_sqft')).toLocaleString('en-CA', {
                    minimumFractionDigits: 2,
                  })
                }}</th>
                <th></th>
            </tr>
        </template>
    </v-data-table>
</b-card>
</template>

<script>
import {
    BCard,
    BTable,
    BButton,
    VBModal,
    BRow,
    BCol,
    BBadge,
    BFormInput,
    BDropdown,
    BTooltip,
    BDropdownItem,
    BFormCheckbox,
    BDropdownForm,
} from "bootstrap-vue";
import vSelect from "vue-select";
import moment from "moment";
import Vuetify, {
    VApp,
    VDataTable,
    VIcon,
    VCol,
    VMenu,
    VBtn,
    VList,
    VListItem,
    VListItemAction,
    VListItemContent,
    VListItemTitle,
    VDivider,
    VListItemGroup,
    VRow,
    VCheckbox
} from 'vuetify/lib'
import {
    UilColumns
} from "@iconscout/vue-unicons";

export default {
    components: {
        BCard,
        BTable,
        BButton,
        BRow,
        BCol,
        BBadge,
        BFormInput,
        BDropdown,
        BTooltip,
        BDropdownItem,
        moment,
        VDataTable,
        VIcon,
        VCol,
        VMenu,
        VBtn,
        VList,
        VListItem,
        VListItemAction,
        VListItemContent,
        VListItemTitle,
        VDivider,
        VListItemGroup,
        VRow,
        VCheckbox,
        BFormCheckbox,
        UilColumns,
        BDropdownForm
    },
    directives: {
        "b-modal": VBModal,
    },
    data() {
        return {
            opportunities: [],
            filteredItems: [],
            dialog: false,
            filters: {
                'account_name': [],
                'sf_opportunity_status': [],
            },
            activeFilters: {},
            desserts: [],
            displayColumnsCheck: [
                "sf_opportunity_id",
                "address",
                "account_name",
                "rentable_sqft",
                "sf_opportunity_status",
            ],
            fields: [{
                    key: "sf_opportunity_id",
                    label: "#",
                    type: "string",
                },
                {
                    key: "address",
                    label: "PROJECT",
                    type: "string",
                },
                {
                    key: "account_name",
                    label: "CLIENT",
                    type: "string",
                },
                {
                    key: "rentable_sqft",
                    label: "RENTABLE SQFT",
                    type: "float",
                },
                {
                    key: "sf_opportunity_status",
                    label: "STATUS",
                    type: "string",
                },
            ],
            search:''
        };
    },
    computed: {
        headers() {
            return [{
                    text: "#",
                    value: "sf_opportunity_id",
                    class: 'header-row-color' 
                },
                {
                    text: "PROJECT",
                    value: "address",
                },
                {
                    text: "CLIENT",
                    value: "account_name",
                    filterable:true,
                    filter: value => {
                        return this.activeFilters.account_name ? this.activeFilters.account_name.includes(value) : false;
                    }
                },
                {
                    text: "RENTABLE SQFT",
                    value: "rentable_sqft",
                },
                {
                    text: "STATUS",
                    value: "sf_opportunity_status",
                    filterable:true,
                    filter: value => {
                        return this.activeFilters.sf_opportunity_status ? this.activeFilters.sf_opportunity_status.includes(value) : false;
                    }
                },
            ].filter(s => this.displayColumnsCheck.includes(s.value));
        },
        formTitle() {
            return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
        },
    },
    beforeMount() {
        //this.getQuotes();
    },
    watch: {
        dialog(val) {
            val || this.close()
        },
        desserts(val) {
            this.initFilters()
        }
    },

    created() {
        this.initialize()
        this.getOpportunities()
    },

    methods: {
        getOpportunities() {
            this.$http
                .get("/opportunities", {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                })
                .then((response) => {
                    this.opportunities = response.data;
                    this.totalRows = this.opportunities.length;
                    //this.totalRows = this.quotes.length;
                    this.isBusy = false;
                    this.initFilters()
                })
                .catch((error) => {
                    this.showToast("danger", error.response.data.message);
                });
        },
        clicked(item) {
            // console.log(item);
            const {
                sf_opportunity_id
            } = item;
            this.$router.push({
                path: `/opportunities/${sf_opportunity_id}`,
            });
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        relativeTime(date) {
            return moment(date).fromNow();
        },
        formatDatetime(date) {
            return moment(date).format("MM-DD-YYYY HH:mm:ss");
        },
        initialize() {
            //this.initFilters()
        },

        initFilters() {
            let col = '';
            for (col in this.filters) {
                this.filters[col] = this.opportunities.map((value) => value[col]).filter((value, index, _arr) => _arr.indexOf(value) == index).sort();
            }
            this.activeFilters = Object.assign({}, this.filters)
            console.log(this.activeFilters);
        },

        toggleAll(col) {
            this.activeFilters[col] = this.opportunities.map((value) => value[col]).filter((value, index, _arr) => _arr.indexOf(value) == index).sort();
        },
        isFilterActive(name, value) {
            return this.activeFilters[name] && this.activeFilters[name].includes(value) ? true : false;
            //return this.activeFilters[name] ? this.activeFilters[name].includes(value) : true;
        },

        clearAll(col) {
            this.activeFilters[col] = []
        },
        sumField(key) {
            // sum data in give key (property)
            //let itemsFiltered = this.$refs.myTable.$children[0].computedItems
            return this.filteredItems.reduce((a, b) => a + (b[key] || 0), 0);
        },

        currentItems: function (value) {
            this.filteredItems = value
        }
    },
};
</script>

<style scoped>
.identified {
    background-color: #0d6efd1f;
    color: #0d6efd;
}

.quotesent {
    background-color: #6610f21f;
    color: #6610f2;
}

.quoterevised {
    background-color: #7367f01f;
    color: #7367f0;
}

.quotehardened {
    background-color: #d633841f;
    color: #d63384;
}

.approved {
    background-color: #00cfe81f;
    color: #00cfe8;
}

.won {
    background-color: #28c76f1f;
    color: #28c76f;
}

.lost {
    background-color: #6c757d1f;
    color: #4b4b4b;
}

.onhold {
    background-color: #ff9f431f;
    color: #ff9f43;
}

.invoice-filter-select {
    min-width: 190px;
}

.v-list {
    height: 400px;
    /* or any height you want */
    overflow-y: auto
}

.header-row-color{
    background: rgba(92, 177, 231, 0.12) !important;
}
</style>
<style scoped>
.v-data-table >>> .v-data-table-header {
  background-color: rgba(92, 177, 231, 0.12) !important;
}
</style>
<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";
</style>
